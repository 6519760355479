/**
 * Maskinia - add event view_item
 */

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';
import MetaDispatcher from 'Store/Meta/Meta.dispatcher';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import ProductDispatcher from 'Store/Product/Product.dispatcher';
import { addRecentlyViewedProduct } from 'Store/RecentlyViewedProducts/RecentlyViewedProducts.action';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';
import { RootState } from 'Util/Store/Store.type';
import {
    ProductPageContainerMapDispatchProps,
    ProductPageContainerMapStateProps,
    ProductPageContainerProps,
} from 'SourceRoute/ProductPage/ProductPage.type';

/** @namespace Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ProductPageContainerMapStateProps => ({
    isOffline: state.OfflineReducer.isOffline,
    product: state.ProductReducer.product,
    metaTitle: state.MetaReducer.title,
    isMobile: state.ConfigReducer.device.isMobile,
    store: state.ConfigReducer.code,
    areReviewsEnabled: state.ConfigReducer.reviews_are_enabled,
});

/** @namespace Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): ProductPageContainerMapDispatchProps => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE, state)),
    requestProduct: (options) => ProductDispatcher.handleData(dispatch, options),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateBreadcrumbs: (breadcrumbs, prevCategoryId) => BreadcrumbsDispatcher.updateWithProduct(breadcrumbs, prevCategoryId, dispatch),
    updateMetaFromProduct: (product) => MetaDispatcher.updateWithProduct(product, dispatch),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    addRecentlyViewedProduct: (product, store) => dispatch(addRecentlyViewedProduct(product, store)),
});

import { ProductPageContainer as SourceProductPageContainer
} from 'SourceRoute/ProductPage/ProductPage.container'
import ReactGA from "react-ga4";

/** @namespace Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {

    componentDidUpdate(prevProps: ProductPageContainerProps): void {
        const {
            isOffline,
            productSKU,
            product,
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: prevProduct,
        } = prevProps;

        const { sku: stateSKU } = history?.location?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If product object was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (JSON.stringify(product) !== JSON.stringify(prevProduct)) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
            //Maskinia send view_item event to analytics
            this.sendAnalyticsEvent();
        }

        this._addToRecentlyViewedProducts();
    }

    sendAnalyticsEvent() {
        //Maskinia send view_item event to analytics
        try {
            //Maskinia - add event begin_checkout
            //Get cart
            const product = this.props.product;
            // @ts-ignore
            let items = [];
            items.push({item_id: product.sku,
                item_name : product.name});

            //Maskinia - add tracking event add to cart
            ReactGA.gtag("event","view_item", {
                currency: "SEK",
                items: items
            });
        } catch (error){
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
